<template>
  <div
    v-loading="loading"
    element-loading-background="rgba(122, 122, 122, 0.1)"
    element-loading-text="Please wait"
    :element-loading-spinner="svg"
    element-loading-svg-view-box="-10, -10, 50, 50"
    style="z-index: 1"
  >
    <div class="card card-lead-by-sources">
      <!-- v-model:modelPolicyStatus="filter.policy_status" -->
      <vc-report-filter
        v-model:modelStartDate="filter.start_date"
        v-model:modelEndDate="filter.end_date"
        v-model:modelAgent="filter.agent"
        show-agent
        show-export-reset-first-row
        show-export-reset-second-row
        :show-export-second="false"
        :show-reset-second="false"
        @reset="reset"
        @export="exportData"
        :export-url="exportUrl"
        is-agent-multiple
        :showExportResetSecondRow="false"
        :agentPlaceHolder="'Select Closed By Sales Agent'"
      />

      <div class="card-body card-custom-spacing-tblf-10 bg-white">
        <div class="pt-0 p-0" v-if="adminReports && adminReports.length > 0">
          <div id="displayResponseData">
            <!-- class="table table-hover bg-bai-table-header table-bai-striped table-row-bordered border gy-7 gs-7 mb-0" -->
            <el-table :data="adminReports">
              <el-table-column
                v-for="list in authorized_headers"
                :key="list.label"
                :fixed="list.isFixed"
                :width="list.width"
              >
                <template #header>
                  <span v-html="list.label" />
                </template>
                <template #default="scope">
                  <span v-if="list.isIndex">
                    {{ scope.$index + 1 }}
                  </span>
                  <span v-else-if="list.isFormat">
                    {{ common.setDateTimeOnly(scope.row[list.value]) }}
                  </span>
                  <span v-else-if="list.isCount">
                    <span
                      v-for="(n, index) in scope.row[list.value]"
                      :key="index"
                    >
                      {{ index + "(" + n + "), " }}
                    </span>
                  </span>

                  
                  <span
                    v-else
                    :class="list.cn"
                    v-html="scope.row[list.value]"
                  />
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div v-else>
          <div v-if="loading">
            <span>Loading Information Please Wait...</span>
          </div>
          <div v-else class="container-fluid p-5">
            <div class="row">
              <div class="col-12">
                <el-empty description="No Records Found" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer bg-white">
        <div class="container-fluid p-0">
          <div class="row">
            <div class="col-4">
              <div
                class="
                  col-sm-12 col-md-5
                  d-flex
                  align-items-center
                  justify-content-center justify-content-md-start
                "
              >
                <div class="dataTables_length" id="kt_customers_table_length">
                  <label>
                    <el-select
                      v-model="filter.per_page"
                      @change="filter.page = 1"
                      size="large"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="page in per_pages"
                        :key="page"
                        :value="page"
                      >
                        {{ page }}
                      </el-option>
                    </el-select>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-4 text-center">
              <span class="text-muted d-block mt-2">
                Showing
                <span v-if="theTable.total > 0">
                  {{ theTable.from }} to {{ theTable.to }} of
                </span>
                <span v-else> {{ theTable.total }} of </span>
                {{ theTable.total }}
              </span>
            </div>
            <div class="col-4">
              <div style="float: right">
                <el-pagination
                  v-if="theTable"
                  v-model:currentPage="filter.page"
                  :page-size="parseInt(theTable.per_page)"
                  :small="large"
                  layout="prev, pager, next"
                  :total="theTable.total"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Report from "@/core/services/Callable/Report";
import { closed_leads_table } from "@/core/utils/reports";
import { store } from "@/store";
import moment from "moment";
import { common } from "@/store/stateless";

const filter = ref({
  start_date: "",
  end_date: "",
  agent: "",
  per_page: 50,
  page: 1,
});

const exportUrl = ref("");

const loading = ref(false);

const adminReports = computed(() => {
  return store.getters.getClosedLeadsReport;
});

const theTable = computed(() => {
  return store.state.report.closed_leads;
});

const role = store.getters.myRole;

const authorized_headers = computed(() => {
  if(![1].includes(role)){
    return closed_leads_table
    .filter(y => y.value != 'email')
    .filter(y => y.value != 'mobile')
  }
  return closed_leads_table
})

onMounted(async () => {
  setCurrentPageBreadcrumbs("Closed Leads", [{ label: "Reports", value: "" }]);
  filter.value.start_date = moment().startOf("year").format("YYYY-MM-DD");
  filter.value.end_date = moment().endOf("year").format("YYYY-MM-DD");
  filter.value.year = moment().format("YYYY");
  await searchData(filter.value);
});

watch(
  [() => filter.value],
  async ([newValue], [oldValue]) => {
    exportUrl.value = '';
    searchData(newValue);
  },
  {
    deep: true,
  }
);

const searchData = async (value) => {
  loading.value = true;
  const data = await Report.getClosedLeadsReport(value);
  if (value.export) {
    exportUrl.value = data;
    // window.open(data);
  }
  loading.value = false;
};

const reset = async () => {
  console.log("reset");
  filter.value.start_date = moment().startOf("year").format("YYYY-MM-DD");
  filter.value.end_date = moment().endOf("year").format("YYYY-MM-DD");
  filter.value.agent = "";
  filter.value.page = 1;
  // await searchData(filter.value);
};

const exportData = () => {
  const payload = {
    ...filter.value,
    export: true,
  };
  searchData(payload);
  console.log("export");
};
</script>
<style>
.text-bold {
  font-weight: bold !important;
  color: rgb(34, 34, 97) !important;
}
</style>
